import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { BreakpointProvider } from 'react-socks'

import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import { useHomeData } from '../../hooks'
import Section from '../Section'
import { Gallery, GalleryImage } from 'react-gesture-gallery'
import Image1 from '../../static/img/home/iphone.svg'
import Image2 from '../../static/img/home/Btt.svg'
import LeftTriangle from '../../static/img/home/triangle_left_conversationalApps.svg'
import RightTriangle from '../../static/img/home/triangles_right_conversationalApps.svg'
import Fade from 'react-reveal/Fade'
import './scss/gallery.scss'

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #6f16ff;
`
const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;

  color: #ffffff;
`

const ConversationalApps = props => {
  const [index, setIndex] = React.useState(0)
  const bg = <Bg />
  const {
    gallery_image_1,
    gallery_image_2,
    gallery_image_1_mobile,
    gallery_image_2_mobile,
    chatbots_mbl,
  } = useStaticQuery(
    graphql`
      query {
        gallery_image_1: file(
          relativePath: { eq: "home/chatbot_home_gallery_1.png" }
        ) {
          childImageSharp {
            fixed(width: 670) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        gallery_image_2: file(
          relativePath: { eq: "home/chatbot_home_gallery_2.png" }
        ) {
          childImageSharp {
            fixed(width: 670) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        gallery_image_1_mobile: file(
          relativePath: { eq: "home/mobile_chatbot_home_gallery_1.png" }
        ) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        gallery_image_2_mobile: file(
          relativePath: { eq: "home/mobile_chatbot_home_gallery_2.png" }
        ) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        chatbots_mbl: file(relativePath: { eq: "home/chatbot_mbl.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <BreakpointProvider>
      <Section background={bg}>
        <Flex
          pt={[40, 60]}
          pb={[70, 140]}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <DesktopFlex>
            <Gallery
              index={index}
              onRequestChange={i => {
                setIndex(i)
              }}
            >
              <Img
                style={{ alignSelf: 'center' }}
                fixed={gallery_image_1.childImageSharp.fixed}
              />
              <Img
                style={{ alignSelf: 'center' }}
                fixed={gallery_image_2.childImageSharp.fixed}
              />
            </Gallery>
          </DesktopFlex>
          <MobileFlex>
            <Gallery
              index={index}
              onRequestChange={i => {
                setIndex(i)
              }}
            >
              <Img
                style={{ alignSelf: 'center' }}
                fixed={gallery_image_1_mobile.childImageSharp.fixed}
              />
              <Img
                style={{ alignSelf: 'center' }}
                fixed={gallery_image_2_mobile.childImageSharp.fixed}
              />
            </Gallery>
          </MobileFlex>
          {/* <DesktopFlex>
              <Img
                style={{ alignSelf: 'center' }}
                fixed={chatbots.childImageSharp.fixed}
              />
            </DesktopFlex>
            <MobileFlex>
              <Img
                style={{ alignSelf: 'center' }}
                fixed={chatbots_mbl.childImageSharp.fixed}
              />
            </MobileFlex> */}
          {/* </Fade> */}
        </Flex>
      </Section>
      <DesktopFlex>
        <LeftTriangle
          style={{ position: 'absolute', left: 80, top: 1820 }}
        ></LeftTriangle>
        <RightTriangle
          style={{ position: 'absolute', right: 120, top: 1440 }}
        ></RightTriangle>
      </DesktopFlex>
    </BreakpointProvider>
  )
}

export default ConversationalApps
