import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { BreakpointProvider } from 'react-socks'

import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import { useHomeData } from '../../hooks'
import Section from '../Section'
import RightTriangle from '../../static/img/home/triangles_right_messageConversation.svg'
import LeftTriangle from '../../static/img/home/triangles_left_messageConversation.svg'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import Fade from 'react-reveal/Fade'
import Reveal from 'react-reveal/Reveal'

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: -280px;
  width: 100%;
  height: 150%;
  z-index: -1;
  background: linear-gradient(
    178.1deg,
    #2e203b 16.5%,
    #2e203b 76.06%,
    rgba(46, 32, 59, 0) 89.11%
  );
  transform: rotate(-180deg);
  @media (max-width: 512px) {
    background: linear-gradient(
      180deg,
      #2e203b 59.56%,
      #2e203b 93.93%,
      rgba(46, 32, 59, 0) 99.77%
    );
    height: 110%;
    margin-top: -85px;
  }
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 132.19%;
  /* or 42px */
  margin-bottom: 8px;
  text-align: center;

  color: #ffffff;
`
const SubTitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  color: #ffffff;
`

const MessageConversation = props => {
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [0.9, 1])
  const {
    acf: {
      messaging_title,
      messaging_title_mobile,
      bottom_content_block,
      bottom_content_block_mobile,
    },
  } = useHomeData(props.pageContext.lang)
  const {
    messaging_image,
    messaging_image_mobile,
    diagram_image,
    diagram_mobile,
  } = useStaticQuery(
    graphql`
      query {
        messaging_image: file(
          relativePath: { eq: "home/messaging_image.png" }
        ) {
          childImageSharp {
            fixed(width: 800) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        messaging_image_mobile: file(
          relativePath: { eq: "home/messaging_image_mobile.png" }
        ) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        diagram_image: file(relativePath: { eq: "home/diagram_image.png" }) {
          childImageSharp {
            fixed(width: 1108) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        diagram_mobile: file(relativePath: { eq: "home/diagram_mobile.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const bg = <Bg></Bg>
  return (
    <BreakpointProvider>
      <Section background={bg}>
        <Flex
          pt={[40, 20]}
          pb={[90, 120]}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <DesktopFlex>
            <Img
              style={{ marginTop: -180 }}
              fixed={messaging_image.childImageSharp.fixed}
            ></Img>
          </DesktopFlex>
          <MobileFlex>
            <Img
              style={{ marginTop: -140 }}
              fixed={messaging_image_mobile.childImageSharp.fixed}
            ></Img>
          </MobileFlex>
          <Fade bottom distance="30px">
            <Box width={[1, 300]} py={[0, 10]}>
              <Title>{messaging_title_mobile || messaging_title}</Title>
            </Box>
            <Box width={[350, 500]}>
              <SubTitle>
                {bottom_content_block_mobile || bottom_content_block}
              </SubTitle>
            </Box>
          </Fade>
          <DesktopFlex>
            <Fade bottom distance="30px">
              <Img fixed={diagram_image.childImageSharp.fixed}></Img>
            </Fade>
          </DesktopFlex>

          <MobileFlex>
            <Img fixed={diagram_mobile.childImageSharp.fixed}></Img>
          </MobileFlex>
        </Flex>
      </Section>
      <DesktopFlex>
        <LeftTriangle
          style={{ position: 'absolute', left: 0, top: 668 }}
        ></LeftTriangle>
        <RightTriangle
          style={{ position: 'absolute', right: 0, top: 773 }}
        ></RightTriangle>
      </DesktopFlex>
    </BreakpointProvider>
  )
}

export default MessageConversation
