import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'

import Benefits from '../components/home/benefits'
import ConversationalApps from '../components/home/conversationalApps'
import Customers from '../components/home/customers'
import Footer from '../components/footer'
import Head from '../components/head'
import Header from '../components/home/header'
import HowHubtypeWorks from '../components/home/howWorks'
import MessageCoversation from '../components/home/messageConversation'
import Navbar from '../components/Navbar'
import Services from '../components/home/services'
import UsesCases from '../components/home/useCases'
import { useHomeData } from '../hooks'

const HomePage = props => {
  const { pageContext } = props;

  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = useHomeData(pageContext.lang)
  // useLangRedirect({
  //   'en-US': '/',
  //   en: '/',
  //   'en-UK': '/uk/',
  //   'es-ES': '/es/',
  //   es: '/es/',
  // })
  return (
    <BreakpointProvider>
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Head
          alternates={pageContext.i18n}
          autopilot={pageContext.autopilot}
          description={yoast_wpseo_metadesc}
          lang={pageContext.lang}
          title={yoast_wpseo_title}
          uri={pageContext.relativeUrl}
        />
        <Navbar
          py={[12, 36]}
          theme="dark"
          yThreshold={40}
          style={{ background: 'transparent', borderBottom: 'none' }}
          styleThreshold={{
            background: '#262626',
            borderBottom: 'none',
            paddingTop: 12,
            paddingBottom: 12,
          }}
          {...props}
        />
        <Header {...props} />
        <MessageCoversation {...props} />
        <ConversationalApps {...props} />
        <HowHubtypeWorks {...props} />
        <UsesCases {...props} />
        <Customers {...props} />
        <Benefits {...props} />
        <Services {...props} />
        <Footer {...props} />
      </Flex>
    </BreakpointProvider>
  )
}
export default HomePage
