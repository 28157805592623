import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'

import { useHomeData } from '../../hooks/use-home-data'
import Section from '../Section'
import { WhatsappBanner } from '../social/whatsappBanner'
import { langs } from '../../langs'
import Cookies from '../cookies'
import { OutlineButton } from '../../components/buttons'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: -280px;
  width: 100%;
  height: 150%;
  z-index: -1;
  background: linear-gradient(
    178.1deg,
    #2e203b 16.5%,
    #2e203b 76.06%,
    rgba(46, 32, 59, 0) 89.11%
  );
  transform: rotate(-180deg);
  @media (max-width: 512px) {
    background: linear-gradient(
      180deg,
      #2e203b 59.56%,
      #2e203b 93.93%,
      rgba(46, 32, 59, 0) 99.77%
    );
    height: 110%;
    margin-top: -85px;
  }
`

const Title = styled.h1`
  margin: 60px 0px 28px;
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 86px;
  line-height: 97px;
  color: white;
  @media (max-width: 512px) {
    font-size: 44px;
    line-height: 60px;
    padding: 0px 15px;
  }
`

const Tagline = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: white;
  max-width: 700px;
  @media (max-width: 512px) {
    padding: 0px 24px 45px;
    font-size: 16px;
  }
`
const SmallTagline = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154.22%;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
`

const StartButtonText = styled.a`
  font-family: Heebo;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 3px;
`
const Flexi = styled(Flex)`
  position: absolute;
  right: 0px;
  top: 143px;
  @media (max-width: 512px) {
    top: 525px;
  }
`

const DeveloperButtonFlex = styled(Flex)`
  background: linear-gradient(180deg, #464d65 0%, #313647 100%);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const Header = props => {
  const {
    acf: {
      whatsapp_banner,
      header_title,
      header_title_mobile,
      header_subtitle,
      header_subtitle_mobile,
      whatsapp_banner_learnMore,
      button_start_conversation,
      path_button_start_conversation,
      button_developer,
      path_button_developer,
    },
  } = useHomeData(props.pageContext.lang)
  const { bgHeader, bgHeaderMobile } = useStaticQuery(
    graphql`
      query {
        bgHeader: file(relativePath: { eq: "home/bg_header@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 75) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bgHeaderMobile: file(
          relativePath: { eq: "home/bg_header_mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 75) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  const whatsappClick = () =>
    (window.location = `${langs[props.pageContext.lang]}/whatsapp-business-api`)
  let bg = (
    <>
      <Flex
        flex="1 1 auto"
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -2,
          width: '100%',
          height: '100%',
          backgroundColor: '#362745',
        }}
      />
      <D>
        <Img
          fluid={bgHeader.childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />
      </D>
      <M>
        <Img
          fluid={bgHeaderMobile.childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />
      </M>
    </>
  )
  const ButtonText = () => (
    <SmallTagline href="//www.botonic.io" style={{ color: 'white' }}>
      DEVELOPER?
    </SmallTagline>
  )

  const DeveloperButton = () => (
    <Flexi>
      <motion.div whileHover={{ scale: 1.05 }}>
        <DeveloperButtonFlex px={20} py={12}>
          <ButtonText></ButtonText>
        </DeveloperButtonFlex>
      </motion.div>
    </Flexi>
  )

  return (
    <>
      <Section background={bg}>
        <Flex
          alignItems="center"
          flexDirection="column"
          pt={[80, 145]}
          pb={[130, 190]}
          css={{ position: 'relative' }}
        >
          <motion.div whileHover={{ scale: 1.02 }}>
            <WhatsappBanner
              bannerText={whatsapp_banner_learnMore}
              onClick={whatsappClick}
              alignSelf="center"
              style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
            >
              {whatsapp_banner}
            </WhatsappBanner>
          </motion.div>

          <Cookies />

          <Title>
            <M>{header_title_mobile || header_title}</M>
            <D>{header_title}</D>
          </Title>
          <Tagline>
            <M>{header_subtitle_mobile || header_subtitle}</M>
            <D>{header_subtitle}</D>
          </Tagline>
          {/* <motion.div whileHover={{ scale: 1.05 }}>
          <OutlineButton
            color="white"
            hoverBackground="#ff4d76"
            borderColor="#FF2B5E"
            style={{
              minWidth: '150px',
              minHeight: '50px',
              background: '#FF2B5E',
              marginBottom: 19,
            }}
          >
            <StartButtonText>{button_start_conversation}</StartButtonText>
          </OutlineButton>
        </motion.div> */}
          {/* <motion.div whileHover={{ scale: 1.05 }}>
          <SmallTagline href="//www.botonic.io">
            {button_developer}
          </SmallTagline>
        </motion.div> */}
        </Flex>
      </Section>

      <DeveloperButton></DeveloperButton>
    </>
  )
}

export default Header
