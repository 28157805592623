import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useHomeData } from '../../hooks'
import Section from '../Section'
import { OutlineButton } from '../../components/buttons'
import { langs } from '../../langs'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import ButtonUseCase from '../../static/img/home/use_case_button.svg'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import Fade from 'react-reveal/Fade'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #2e203b;
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #ffffff;
  margin-top: 80;
`

const Subtitle = styled(Title)`
  font-size: 30px;
  margin-bottom: 14px;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 21px;
  line-height: 23px;
  color: #ac9bc8;
`

const UseCase = ({ useCase, className, lang }) => {
  return (
    <Flex
      className={className}
      mr={[15, 170]}
      pt={[20, 30]}
      style={{ borderRadius: 4 }}
    >
      <DesktopFlex>
        <Flex mr={[20, 0]}>
          {useCase.pic && (
            <Img
              style={{ marginRight: 30, marginLeft: 30 }}
              fixed={useCase.pic.localFile.childImageSharp.fixed}
            />
          )}
        </Flex>
        <Flex flexDirection="column" width={[1, 1]} pt={[15, 15]}>
          <Subtitle>{useCase.title}</Subtitle>
          <Flex flexDirection={['column', 'row']}>
            <Flex width={[1]}>
              <Description
                dangerouslySetInnerHTML={{ __html: useCase.description }}
              />
            </Flex>
            <Flex
              width={[1, 1 / 4]}
              pb={[24, 0]}
              mt={[0, 23]}
              justifyContent={['', 'flex-end']}
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{
                  scale: 0.8,
                  rotate: -90,
                  borderRadius: '100%',
                }}
              >
                <a
                  style={{ cursor: 'pointer' }}
                  href={`${langs[lang]}/${useCase.buttonPath}`}
                >
                  <ButtonUseCase
                    style={{
                      marginTop: 20,
                      marginRight: 16,
                      marginBottom: 4,
                    }}
                  ></ButtonUseCase>
                </a>
              </motion.div>
            </Flex>
          </Flex>
        </Flex>
      </DesktopFlex>
      <MobileFlex>
        <Flex flexDirection="column" width={1} px={12}>
          <Subtitle>{useCase.title}</Subtitle>
          <Flex flexDirection={['column', 'row']}>
            <Flex width={[1]}>
              <Description
                dangerouslySetInnerHTML={{ __html: useCase.description }}
              />
            </Flex>
          </Flex>
          <Flex
            style={{ width: '127%' }}
            justifyContent="flex-end"
            mt={-90}
            ml={10}
          >
            <a
              style={{ cursor: 'pointer' }}
              href={`${langs[lang]}/${useCase.buttonPath}`}
            >
              <ButtonUseCase style={{ margin: 20 }}></ButtonUseCase>
            </a>
          </Flex>
        </Flex>
        <Flex mr={[20, 0]}>
          {useCase.pic && (
            <Img
              style={{
                height: 50,
                width: 50,
              }}
              fixed={useCase.pic.localFile.childImageSharp.fixed}
            />
          )}
        </Flex>
      </MobileFlex>
    </Flex>
  )
}

const StyledUseCase = styled(UseCase)`
  background: linear-gradient(180deg, #523b6a 0%, #3b2a4b 100%);
  margin-bottom: 10px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #423350;
  }
`

const UseCases = props => {
  const {
    acf: { use_case1, use_case2, use_case3 },
  } = useHomeData(props.pageContext.lang)
  const { image, phoneMobile } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "home/useCase_image.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        phoneMobile: file(
          relativePath: { eq: "home/useCase_image_mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <>
      <Section
        pt={[60, 110]}
        pb={[450, 170]}
        px={[15, 0]}
        css={{ position: 'relative' }}
        background={<Bg />}
      >
        <Title>Use cases</Title>
        <Flex
          pt={[0, 50]}
          flexDirection={'column'}
          pl={[0, 0]}
          style={{ maxWidth: 1000 }}
        >
          <Fade left distance="70px">
            <StyledUseCase useCase={use_case1} lang={props.pageContext.lang} />
          </Fade>
          <Fade left distance="70px">
            <StyledUseCase useCase={use_case2} lang={props.pageContext.lang} />
          </Fade>
          <Fade left distance="70px">
            <StyledUseCase useCase={use_case3} lang={props.pageContext.lang} />
          </Fade>
        </Flex>
        <MobileFlex>
          <Img
            style={{
              position: 'absolute',
              width: 350,
              height: 420,
              bottom: 0,
              zIndex: -1,
            }}
            fluid={phoneMobile.childImageSharp.fluid}
          />
        </MobileFlex>
      </Section>
      <DesktopFlex>
        <Img
          style={{
            position: 'absolute',
            width: 324,
            height: 583,
            top: 3495,
            right: 0,
          }}
          fluid={image.childImageSharp.fluid}
        />
      </DesktopFlex>
    </>
  )
}

export default UseCases
