import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { BreakpointProvider } from 'react-socks'

import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import { useHomeData } from '../../hooks'
import Section from '../Section'

import Arrows from '../../static/img/home/work_arrows.svg'
import ArrowsMobile from '../../static/img/home/work_arrows_mobile.svg'
import Image2 from '../../static/img/home/work_image2.svg'
import LeftTriangle from '../../static/img/home/triangle_left_conversationalApps.svg'
import RightTriangle from '../../static/img/home/triangles_right_conversationalApps.svg'
import Fade from 'react-reveal/Fade'

const Bg = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 45px;
  /* identical to box height */
  padding: 30px;
  text-align: center;

  color: #333333;

  @media (max-width: 512px) {
    padding-bottom: 53px;
    padding-left: 103px;
    text-align: left;
    padding
  }
`

const WorkTitle = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height */

  color: #ffffff;
  @media (max-width: 512px) {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height */

    color: #000000;
  }
`

const HowWorks = props => {
  const {
    acf: { title_work, columns },
  } = useHomeData(props.pageContext.lang)
  const {
    mobile_arrows,
    workImage2,
    diagram_image,
    diagram_mobile,
  } = useStaticQuery(
    graphql`
      query {
        mobile_arrows: file(
          relativePath: { eq: "home/work_arrows_mobile.png" }
        ) {
          childImageSharp {
            fixed(width: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        workImage2: file(relativePath: { eq: "home/work_image2.png" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        diagram_image: file(relativePath: { eq: "home/diagram_image.png" }) {
          childImageSharp {
            fixed(width: 800) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        diagram_mobile: file(relativePath: { eq: "home/diagram_mobile.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  let Bg1 = props => {
    return (
      <Flex>
        <Box mt={[0, 2]}>
          <WorkTitle style={{ padding: '20px 20px 20px 0px' }}>
            {props.columns.title}
          </WorkTitle>
          <Img
            style={{ position: 'absolute' }}
            fixed={props.columns.logo.localFile.childImageSharp.fixed}
          ></Img>
        </Box>
      </Flex>
    )
  }
  let Bg1_mobile = props => {
    return (
      <Flex>
        <Box>
          <Img fixed={props.columns.logo.localFile.childImageSharp.fixed}></Img>
          <WorkTitle style={{ padding: 50 }}>{props.columns.title}</WorkTitle>
        </Box>
      </Flex>
    )
  }
  const bg = <Bg />

  return (
    <BreakpointProvider>
      <Section background={bg}>
        <Flex
          pt={[20, 40]}
          pb={[60, 100]}
          flexDirection="column"
          alignItems={['flex-start', 'center']}
        >
          <Title>{title_work}</Title>
          <DesktopFlex>
            <Arrows></Arrows>
          </DesktopFlex>
          <MobileFlex width={1}>
            <Img
              style={{ position: 'absolute', left: 0, top: -5 }}
              fixed={mobile_arrows.childImageSharp.fixed}
            ></Img>
          </MobileFlex>
          <DesktopFlex flexDirection="row" mt={-55} ml={5}>
            {Object.values(columns).map((item, i) => (
              <Flex flexDirection="column" mx={20}>
                <Fade>
                  <Bg1 columns={item} value={i}></Bg1>

                  <Box width={250} mt={15} pt={60}>
                    <div
                      style={{ color: '#696973' }}
                      dangerouslySetInnerHTML={{
                        __html: item.desc,
                      }}
                    ></div>
                  </Box>
                </Fade>
              </Flex>
            ))}
          </DesktopFlex>
          <MobileFlex flexDirection="column" mt={-55}>
            {Object.values(columns).map((item, i) => (
              <Flex flexDirection="column" mx={20} pb={30}>
                <Bg1_mobile columns={item} value={i}></Bg1_mobile>
                <Box width={[1, 250]} pt={[0, 10]}>
                  <Box
                    pl={[85, 0]}
                    dangerouslySetInnerHTML={{
                      __html: item.desc,
                    }}
                  ></Box>
                </Box>
              </Flex>
            ))}
          </MobileFlex>
        </Flex>
      </Section>
    </BreakpointProvider>
  )
}

export default HowWorks
