import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { OutlineButton } from '../buttons'

import Contact from '../../static/img/home/Btt.svg'

import { useHomeData } from '../../hooks'
import { langs } from '../../langs'

const ServicesContainer = styled(Flex)`
  position: relative;
  background: linear-gradient(to bottom, #6f16ff 0%, #6500cb 100%);
  &:before {
    content: '';
    position: absolute;
    top: -200px;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    background-color: #6f16ff;
    z-index: -10;
  }
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
`
const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(180deg, #6f16ff -16.98%, #6500cb 100%);
`
const StyledContactButton = styled(Contact)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Help = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #bf99ff;
`

const Services = props => {
  const {
    acf: { title, service_button, text_aboveTitle },
  } = useHomeData(props.pageContext.lang)
  return (
    <ServicesContainer
      py={60}
      flexDirection="column"
      alignItems="center"
      justifyContent="stretch"
    >
      <Flex justifyContent="center" display="flex">
        <Help>{text_aboveTitle}</Help>
      </Flex>
      <Title>{title}</Title>
      <Flex justifyContent="center" display="flex">
        <OutlineButton
          color="white"
          hoverBackground="#ff4d76"
          borderColor="#FF2B5E"
          style={{
            minWidth: '150px',
            minHeight: '40px',
            background: '#FF2B5E',
          }}
          href={`${langs[props.pageContext.lang]}/contact`}
        >
          {service_button}
        </OutlineButton>
      </Flex>
    </ServicesContainer>
  )
}

export default Services
