import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { useHomeData } from '../../hooks'
import Section from '../Section'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import LeftTriangle from '../../static/img/home/left_triangle_customers.svg'
import RightTriangle from '../../static/img/home/right_triangle_customers.svg'
import Fade from 'react-reveal/Fade'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #f2f2f2;
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  color: #000000;
`
const QuoteMarks = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 95px;
  color: #000000;
`

const Customers = props => {
  const {
    acf: {
      customers_title,
      customers_description1,
      customers_description2,
      group1_logos,
      group2_logos,
    },
  } = useHomeData(props.pageContext.lang)
  return (
    <Section
      pt={[40, 80]}
      pb={[40, 120]}
      css={{ position: 'relative' }}
      background={<Bg />}
    >
      <Flex justifyContent="center" pb={[0, 100]}>
        <Title>{customers_title}</Title>
      </Flex>
      <Flex flexDirection={['column', 'row']} pb={[10, 50]} px={[15, 0]}>
        <Flex width={[1, 1 / 2]} flexDirection="column">
          <DesktopFlex>
            <QuoteMarks style={{ position: 'absolute', top: 205, left: -32 }}>
              “
            </QuoteMarks>
          </DesktopFlex>
          <Fade left distance="40px">
            <Flex
              pr={[0, 110]}
              pb={[0, 100]}
              flexDirection="column"
              style={{ color: '#696973' }}
              dangerouslySetInnerHTML={{
                __html: customers_description1,
              }}
            ></Flex>
          </Fade>
          <DesktopFlex>
            <QuoteMarks style={{ position: 'absolute', top: 416, left: 155 }}>
              ”
            </QuoteMarks>
          </DesktopFlex>
          <DesktopFlex>
            <Flex width="100%" height="100%" pt={[0, 0]}>
              <Img
                style={{ width: '80%' }}
                fluid={group2_logos.localFile.childImageSharp.fluid}
              ></Img>
            </Flex>
          </DesktopFlex>
        </Flex>
        <MobileFlex flexDirection="column">
          <Flex width="100%" height="100%" pb={[20, 200]} pt={80}>
            <Img
              style={{ width: '100%' }}
              fluid={group1_logos.localFile.childImageSharp.fluid}
            ></Img>
          </Flex>
          <Flex width="100%" height="100%" pt={[20, 80]} pb={100}>
            <Img
              style={{ width: '100%' }}
              fluid={group2_logos.localFile.childImageSharp.fluid}
            ></Img>
          </Flex>
        </MobileFlex>
        <Flex width={[1, 1 / 2]} flexDirection="column">
          <DesktopFlex>
            <Flex width="100%" height="100%" pb={[0, 150]}>
              <Img
                style={{ width: '70%' }}
                fluid={group1_logos.localFile.childImageSharp.fluid}
              ></Img>
            </Flex>
          </DesktopFlex>
          <DesktopFlex>
            <QuoteMarks style={{ position: 'absolute', top: 543, right: 494 }}>
              “
            </QuoteMarks>
          </DesktopFlex>
          <Fade right distance="40px">
            <Flex
              pt={[0, 40]}
              pr={[0, 32]}
              ml={[0, 69]}
              flexDirection="column"
              style={{ color: '#696973' }}
              dangerouslySetInnerHTML={{
                __html: customers_description2,
              }}
            ></Flex>
          </Fade>
          <DesktopFlex>
            <QuoteMarks style={{ position: 'absolute', top: 836, right: 44 }}>
              ”
            </QuoteMarks>
          </DesktopFlex>
        </Flex>
      </Flex>
      <LeftTriangle
        style={{ position: 'absolute', top: 160, left: -23, zIndex: -1 }}
      />
      <RightTriangle
        style={{ position: 'absolute', top: 529, right: 20, zIndex: -1 }}
      />
    </Section>
  )
}

export default Customers
