import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Banner = styled(Flex)`
  position: relative;
  background: linear-gradient(180deg, #ffffff 0%, #d1d1d1 100%);
  padding: 15px 10px;
  height: ${props => props.height}px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: white;
  text-align: left;
  cursor: pointer;
  border-left-radius: 4px;
  &:before,
  &:after {
    position: absolute;
    transition: 0.3s;
    top: 0;
    left: -10px;
    content: '';
    width: 20px;
    height: ${props => props.height}px;
    background-color: linear-gradient(180deg, #ffffff 0%, #d1d1d1 100%);
    
`
const BannerNext = styled(Flex)`
  position: relative;
  background: linear-gradient(180deg, #a771ff 0%, #6f16ff 100%);
  padding: 15px 30px;
  height: ${props => props.height}px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  border-right-radius: 4px;
  line-height: 16px;
  color: white;
  text-align: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 512px) {
    height: 50px;
  }
  &:before,
  &:after {
    position: absolute;
    transition: 0.3s;
    top: 0;
    left: -10px;
    content: '';
    width: 20px;
    height: ${props => props.height}px;
    background-color: linear-gradient(180deg, #ffffff 0%, #d1d1d1 100%);
    
`
const LearnMore = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154.22%;
  /* or 20px */

  text-transform: uppercase;

  color: #ffffff;
`
const Text = styled.span`
  color: #333333;
  align-self: left;
  font-size: 16px;
  @media (max-width: 512px) {
    font-size: 12px;
  }
`

Banner.defaultProps = {
  color: '#2E203B',
  overColor: '#ff2b5e',
  height: 42,
}

export const WhatsappBanner = props => {
  const { waLogo } = useStaticQuery(
    graphql`
      query {
        waLogo: file(relativePath: { eq: "home/WhatsappLogo.png" }) {
          childImageSharp {
            fixed(width: 22, height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <Flex justifyContent="column" mx={[10, 0]}>
      <Banner {...props}>
        <Flex alignItems="center">
          <Flex mr={12}>
            <Img fixed={waLogo.childImageSharp.fixed} />
          </Flex>

          <Text>{props.children}</Text>
          <Flex ml={3} flexDirection={'column'}></Flex>
        </Flex>
      </Banner>
      <BannerNext
        {...props}
        style={{
          fontFamily: 'Heebo',
          fontSize: 13,
          fontWeight: 400,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          height: 42,
        }}
      >
        <span>LEARN MORE</span>
      </BannerNext>
    </Flex>
  )
}
