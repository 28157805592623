import React, { useState } from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Rectangle from '../static/img/cookies/cookies_rectangle.svg'
import RectangleMobile from '../static/img/cookies/cookies_rectangleMobile.svg'
import Cross from '../static/img/cookies/cross_cookies.svg'
import { DesktopFlex, MobileFlex } from './breakpoints'

const Text = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */
  color: #ffffff;
  margin-bottom: -2px;
`
const Link = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */
  color: #ff2b5e;
  margin-bottom: -2px;
`

const Cookies = props => {
  const [showCookies, setShowCookies] = useState(true)

  if (typeof window !== 'undefined') {
    if (localStorage.getItem('cookies') && showCookies) {
      setShowCookies(false)
    }
  }

  const handleClick = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookies', 'true')
      setShowCookies(false)
    }
  }

  return (
    <>
      {showCookies && (
        <>
          <DesktopFlex>
            <Flex
              style={{ position: 'fixed', left: 10, bottom: 10, zIndex: 10 }}
            >
              <Rectangle />
            </Flex>
            <Flex
              width={270}
              height={47}
              style={{ position: 'fixed', left: 25, bottom: 22, zIndex: 50 }}
            >
              <Flex width={2.7 / 4}>
                <Text>
                  By using our website you are consenting to our use of cookies
                  in accordance to our{' '}
                  <Link style={{ textDecoration: 'none' }} href="/cookies">
                    cookie policy
                  </Link>
                </Text>
              </Flex>
              <Flex mt={15} ml={10} width={1 / 4} justifyContent="center">
                <Cross onClick={() => handleClick()} />
              </Flex>
            </Flex>
          </DesktopFlex>
          <MobileFlex>
            <Flex
              style={{ position: 'fixed', bottom: 10, left: 15, zIndex: 10 }}
            >
              <RectangleMobile />
            </Flex>
            <Flex
              width={400}
              height={20}
              style={{ position: 'fixed', bottom: 25, left: 25, zIndex: 50 }}
            >
              <Flex width={2.7 / 4}>
                <Text>
                  By using our website you are consenting to our use of cookies
                  in accordance to our{' '}
                  <Link style={{ textDecoration: 'none' }} href="/cookies">
                    cookie policy
                  </Link>
                </Text>
              </Flex>
              <Flex mt={10} width={0.5 / 4} justifyContent="center">
                <Cross onClick={() => handleClick()} />
              </Flex>
            </Flex>
          </MobileFlex>
        </>
      )}
    </>
  )
}

export default Cookies
