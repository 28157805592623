import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import { D, M } from '../breakpoints'
import { useHomeData } from '../../hooks'
import Section from '../Section'
import Img from 'gatsby-image'
import ReduceCostsIcon from '../../static/img/home/reduce_costs_icon.svg'
import CustomerSatisfactionIcon from '../../static/img/home/customer_satisfaction_icon.svg'
import LTVIcon from '../../static/img/home/ltv_icon.svg'
import Fade from 'react-reveal/Fade'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #362745;
`

const Title = styled.h1`
  flex: 1 1 auto;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 0px;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 21px;
  line-height: 23px;
  color: #ac9bc8;
`

const Icon = styled(Img)`
  img {
    margin-bottom: 0px;
  }
`

const MobileIcon = styled(Icon)`
  @media (min-width: 512px) {
    display: none !important;
  }
`

const DesktopIcon = styled(Icon)`
  @media (max-width: 512px) {
    display: none !important;
  }
  @media (min-width: 512px) {
    position: absolute;
    left: -40px;
    top: ${({ t }) => -30 + t}px;
  }
`

const Benefit = ({ benefit, t }) => (
  <Flex
    mr={30}
    width={[1, 1 / 3]}
    flexDirection="column"
    css={{ position: 'relative' }}
  >
    <DesktopIcon
      style={{ marginBottom: -20 }}
      t={t}
      fixed={benefit.pic.localFile.childImageSharp.fixed}
    />
    <Fade top distance="50px">
      <Flex mb={12} alignItems="center" justifyContent="center">
        <Title style={{ marginRight: '15%' }} flex="1 1 auto">
          {benefit.title}
        </Title>
        <Flex width={81}>
          <MobileIcon fixed={benefit.pic.localFile.childImageSharp.fixed} />
        </Flex>
      </Flex>
      <Description
        style={{ marginRight: '2%' }}
        dangerouslySetInnerHTML={{ __html: benefit.description }}
      />
    </Fade>
  </Flex>
)

const Benefits = props => {
  const {
    acf: { benefit1, benefit2, benefit3 },
  } = useHomeData(props.pageContext.lang)
  let bg = (
    <Bg>
      <D>
        <ReduceCostsIcon style={{ position: 'absolute', top: '25%' }} />
        <CustomerSatisfactionIcon
          style={{ position: 'absolute', left: '43%', top: -60 }}
        />
        <LTVIcon style={{ position: 'absolute', right: 0, bottom: -40 }} />
      </D>
    </Bg>
  )
  return (
    <Section
      pt={[25, 0]}
      pb={60}
      px={[15, 0]}
      css={{ position: 'relative' }}
      background={bg}
    >
      <Flex flexDirection={['column', 'row']}>
        <Benefit benefit={benefit1} t={-20} />
        <Benefit benefit={benefit2} t={-30} />
        <Benefit benefit={benefit3} t={-40} />
      </Flex>
    </Section>
  )
}

export default Benefits
